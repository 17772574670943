import React from "react"
import { FaCode, FaCogs, FaServer } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Desarrollo Web",
    text: `Soy un entusiasta de las tecnologías Web, por lo cual constantemente investigo y me documento,
     poseo conocimientos en HTML, CSS, Javascript, Jquery, Bootstrap, AngularJS, NodeJS, mongoDB, NPM, Express, Mongoose, SEO, Redes Sociales, entre otras.`,
  },
  {
    id: 2,
    icon: <FaCogs className="service-icon" />,
    title: "Soporte de Aplicaciones",
    text: `Tengo experiencia en recepción, seguimiento y solución de incidencias.
    Testeos para incidentes, mejoras y proyectos.
    Documentación Especificación Funcional, Escenarios de Prueba, Manuales, Minutas y Procedimientos.`,
  },
  {
    id: 3,
    icon: <FaServer className="service-icon" />,
    title: "Infraestructura IT",
    text: `Tengo experiencia atendiendo incidentes que afecten al Soporte de Infraestructura de TI, diagnosticar las causas que los originan y resolverlos o coordinar su solución, asegurando la accesibilidad de los servicios`,
  },
]
